import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  Main,
} from ".";

class App extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
	    <Router>
	      <Routes>
	        <Route path="/" element={<Main />} />
	      </Routes>
	    </Router>		
		);
	}
}

export default App;
