import './App.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';

export class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [], 
			page: 0, 
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
    console.log('hi');

		let url = 'https://api.abovum.io/entries.json'
    fetch(url)
      .then(res => res.json())
      .then(payload => {
        console.log(payload)
        this.setState({items: payload})
      });
	}

	render() {
		if (this.state.page == 3) {
		}

		if (this.state.page == 2) {
		}

    return (
      <div className="App">
        <Container fluid>
          <Row style={{backgroundColor: 'gray', color: 'white'}}>
            <Col sm={{span: 1}} onClick={this.handleClick} className="homeBtn">
              <div style={{border: '1px solid', borderRadius: '5px', margin: '5px', padding: '5px', backgroundColor: '#a0a0ff'}}>Ab Ovum</div>
            </Col>
            <Col sm={{span: 4}}/>

            <Col sm={{span: 2}} onClick={this.handleClick} style={{maxWidth: 'fit-content'}}>
              <div style={{border: '1px solid', borderRadius: '5px', margin: '5px', backgroundColor: '#a0a0ff', display: 'flex'}}>
                <div className="homeBtn" style={{padding: '5px', borderRight: '1px solid'}}>Data</div>
                <div className="homeBtn" style={{padding: '5px', borderRight: '1px solid'}}>Actions</div>
                <div className="homeBtn" style={{padding: '5px'}}>More</div>
              </div>
            </Col>

            <Col sm={{span: 4}}/>
            <Col sm={{span: 1}} onClick={this.handleClick} className="homeBtn">
              <div style={{border: '1px solid', borderRadius: '5px', margin: '5px', padding: '5px', backgroundColor: '#a0a0ff'}}>Profile</div>
            </Col>
          </Row>

          <Row style={{height: '20px'}} />
          {
            this.state.items.map((item, index) => (
  								<Row key={item.id}>
              {
                ['shipment_id', 'b'].map((key) => (
                    <Col style={{border: '1px solid'}}>{item.data[key] ? item.data[key] : 'na'}</Col>
                ))
              }
									</Row>
  							))
          }
        </Container>
      </div>
    );

	}
}

export default Main;
